import '../bootstrap.js';

// Import modules...

import instaWpCreateInertiaApp from '@/onboard/create-app';

const el = document.getElementById("app");
const createInstance = () => {
    instaWpCreateInertiaApp()
};
// on initial fetch the php and wp versions.
(async () => {
    try {
        createInstance();
    } catch (e) {
        
        // Deal with the fact the chain failed
    }
})();
