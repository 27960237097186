import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "ziggy-js";
import * as Sentry from "@sentry/vue";

import NProgress from "nprogress";
import {
    cdn
} from "@/helpers";

import Notifications from "@kyvg/vue3-notification";
import BlankLayout from "@/Layouts/BlankLayout.vue";

import { VueReCaptcha } from "vue-recaptcha-v3";
import tooltip from "@/directives/tooltip.js";

import { createPinia } from "pinia";
const pinia = createPinia();

import { useOnboardStore } from "@/onboard/store";

import usermavenPlugin from "@/plugins/usermaven";
// import googleAnalyticsPlugin from "@/plugins/google_analytics";
import customerIOPlugin from "@/plugins/customerio";
import { i18nVue  } from 'laravel-vue-i18n'

export default function instaWpCreateInertiaApp() {
    createInertiaApp({
        progress: { color: "#15b881" },
        resolve: async (name) => {
            const page = await resolvePageComponent(
                `./Pages/${name}.vue`,
                import.meta.glob("./Pages/**/*.vue"),
            );
            if (page.default.layout === undefined) {
                page.default.layout = BlankLayout;
            }
            return page;
        },
        async setup({ el, App, props, plugin }) {
            const { tenant } = props.initialPage.props;

            Sentry.setUser(null);
            const application = createApp({
                render: () => h(App, props),
            });

            application
                .mixin(Sentry.createTracingMixins({ trackComponents: true }))
                .use(plugin)
                .use(ZiggyVue)
                .use(Notifications)
                .use(i18nVue, {
                    resolve: async lang => {
                        const langs = import.meta.glob('../../../lang/*.json');
                        const langPath = `../../../lang/${lang}.json`;
                        const result = await langs[langPath]();
                        return result;
                    }
                });

            if (import.meta.env.VITE_APP_ENV != "local") {
                Sentry.attachErrorHandler(application, { logErrors: true });
            }

            application.use(pinia);

            const useOnboardStoreObj = useOnboardStore();
            await useOnboardStoreObj.getVersions();
            await useOnboardStoreObj.getOnboardConfigs();

            if (tenant) {
                const onboardStoreObj = useOnboardStore();
                onboardStoreObj.setTenant(tenant);
            }

            application.use(VueReCaptcha, {
                siteKey: import.meta.env.VITE_RECAPTCHA_SITE_KEY,
                loaderOptions: {
                    autoHideBadge: true,
                },
            });

            application.config.globalProperties.cdn = cdn;
            // Register components globally
            application.directive("tooltip", tooltip);

            //Sentry configuration
            if (import.meta.env.VITE_APP_ENV != "local") {
                Sentry.init({
                    app: application,
                    dsn: import.meta.env.VITE_SENTRY_VUE_DSN,
                    // Set tracesSampleRate to 1.0 to capture 100%
                    // of transactions for performance monitoring.
                    // We recommend adjusting this value in production
                    tracesSampleRate: import.meta.env
                        .VITE_SENTRY_TRACES_SAMPLE_RATE,
                    environment: import.meta.env.VITE_APP_ENV,
                    trackComponents: true,
                    logErrors: true,
                });
            }
            if (tenant && tenant.type == "instawp") {
                // usermaven
                application.use(usermavenPlugin, {
                    user: null,
                });
                // googleAnalytics
                // application.use(googleAnalyticsPlugin, {
                //     ga_client_id: window.ga_client_id,
                //     user: null,
                // });

                // customer.io
                application.use(customerIOPlugin, {
                    _cio: window._cio,
                    user: null,
                });
            }

            return application.mount(el);
        },
    });
}

window.axios.defaults.transformRequest.push(function (data) {
    if (this.url != "/api/v2/track-paywall-event") {
        NProgress.start();
    }
    return data;
});

window.axios.defaults.transformResponse.push(function (data) {
    NProgress.done();
    return data;
});
